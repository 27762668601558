import { FormType } from '@/contexts/employer/EmailTemplateMultiBrandContext';
import { getContrastingEmailTextColor } from '@/utils/_shared/getContrastingEmailTextColor';
import { darken, lighten } from '@mui/material';

export const DEFAULT_REPLY_TO_ADDRESS = 'noreply@fountain.com';
export const SYSTEM_USER_EMAIL = 'system@fountain.com'

export function brandingToFormStyles({
  logoUrl,
  colors,
}: {
  logoUrl?: string | undefined;
  colors?:
    | { primary?: string | undefined; secondary?: string | undefined }
    | undefined;
}) {
  const primary = colors?.primary;
  const primaryHover = primary && darken(primary, 0.2);

  const backgroundColor = primary && lighten(primary, 0.8);
  const buttonTextColor = getContrastingEmailTextColor(primary);

  return [
    ['global.logo', logoUrl],
    ['global.backgroundColor', backgroundColor],
    ['global.link.textColor', primary],
    ['global.link.textColorHover', primaryHover],
    ['global.button.backgroundColor', primary],
    ['global.button.backgroundColorHover', primaryHover],
    ['global.button.textColor', buttonTextColor],
    ['header.backgroundColor', '#FFFFFF'],
    ['header.borderColor', backgroundColor],
    ['body.title.textColor', '#0F172A'],
    ['body.textColor', '#0F172A'],
    ['body.backgroundColor', '#FFFFFF'],
    ['footer.logo.url', logoUrl],
    ['footer.backgroundColor', '#FFFFFF'],
    ['footer.borderColor', backgroundColor],
  ] as const;
}

export const EMAIL_FONT_FACES = [
  ['arial', 'Arial'],
  ['arial black', 'Arial Black'],
  ['comic sans ms', 'Comic Sans'],
  ['courier', 'Courier'],
  ['courier new', 'Courier New'],
  ['georgia', 'Georgia'],
  ['helvetica', 'Helvetica'],
  ['impact', 'Impact'],
  ['lucida grande', 'Lucida Grande'],
  ['tahoma', 'Tahoma'],
  ['times', 'Times'],
  ['times-new-roman', 'Times New Roman'],
  ['trebuchet ms', 'Trebuchet MS'],
  ['verdana', 'Verdana'],
  ['inherit', 'Inherit (Web Fonts)'],
] as const;

export function makeDefaultTemplate(): FormType {
  return {
    showingAiStylesFor: '',
    global: {
      fromName: '',
      fromAddress: '',
      logo: '',
      fontFamily: 'helvetica',
      backgroundColor: '#F1F5F9',
      container: {
        borderColor: '#EEEEEE',
        borderRadius: 8,
      },
      link: {
        textColor: '#2600FF',
        textColorHover: '#2E13CA',
      },
      button: {
        backgroundColor: '#2600FF',
        backgroundColorHover: '#2E13CA',
        textColor: '#FFFFFF',
        fontFamily: 'helvetica',
        borderRadius: 8,
        fullWidth: true,
      },
    },
    header: {
      alignment: 'left',
      logo: {
        height: 50,
        spacing: 8,
      },
      backgroundColor: '#FFFFFF',
      borderColor: '#F1F5F9',
    },
    body: {
      alignment: 'left',
      title: {
        fontFamily: 'helvetica',
        fontSize: 16,
        textColor: '#0F172A',
      },
      textColor: '#0F172A',
      backgroundColor: '#FFFFFF',
    },
    footer: {
      alignment: 'left',
      logo: {
        url: '',
        height: 50,
      },
      padding: 8,
      backgroundColor: '#FFFFFF',
      borderColor: '#F1F5F9',
      textColor: '#000000',
      disclaimerText: '',
      privacyPolicyLink: '',
      showManageNotifications: false,
    },
  };
}
