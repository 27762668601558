export const EMPLOYER_BASE_URL = import.meta.env.VITE_EMPLOYER_BASE_URL || '';
export const EMPLOYER_BASE_PATH = import.meta.env.VITE_EMPLOYER_BASE_PATH || '';
export const SECURITY_SERVICE_BASE_URL =
  import.meta.env.VITE_SECURITY_SERVICE_BASE_URL || 'http://localhost:8107';
export const TODO_SERVICE_BASE_URL =
  import.meta.env.VITE_TODO_SERVICE_BASE_URL || 'http://localhost:8109';

export const MAX_CUSTOM_ATTRIBUTE_LABEL_SIZE = 200;
export const MAX_COMPANY_NAME_SIZE = 100;

export enum SidebarMode {
  Open,
  Closed,
  Hidden,
}

export const LoginMethod = {
  Password: 'password',
  Uuid: 'uuid',
  Token: 'token',
} as const;

export type LoginMethodType = (typeof LoginMethod)[keyof typeof LoginMethod];

export const AuthStatus = {
  Authenticated: 'authenticated',
  Unauthenticated: 'unauthenticated',
  NeedsTfaSetup: 'needsTfaSetup',
  AwaitingOtpCode: 'awaitingOtpCode',
  PasswordExpired: 'passwordExpired',
} as const;

export type AuthStatusType = (typeof AuthStatus)[keyof typeof AuthStatus];

export const AuthError = {
  InvalidCredentials: 'invalid-credentials',
  InvalidOtp: 'invalid-otp',
  Locked: 'locked',
  LockedFromInactivity: 'locked-from-inactivity',
  Generic: 'generic',
} as const;

export type AuthErrorType = (typeof AuthError)[keyof typeof AuthError];

export const SYSTEM_USER_EMAIL = 'system@fountain.com';
