import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './router/employer';
import { EMPLOYER_BASE_PATH } from './utils/employer/constants';
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6';

const router = createBrowserRouter(routes, {
  basename: EMPLOYER_BASE_PATH,
});

function App() {
  return (
    <NuqsAdapter>
      <RouterProvider router={router} />
    </NuqsAdapter>
  );
}

export default App;
