export const LoginStep = {
  Login: 'login',
  Password: 'password',
  TfaSetup: 'tfa-setup',
  Otp: 'otp',
  CompanyChoice: 'company-choice',
} as const;

export type LoginStepType = (typeof LoginStep)[keyof typeof LoginStep];

export const LoginStepOrder: LoginStepType[] = [
  LoginStep.Login,
  LoginStep.Password,
  LoginStep.TfaSetup,
  LoginStep.Otp,
  LoginStep.CompanyChoice,
];
