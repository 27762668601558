import { getLuminance } from '@mui/material';

export function getContrastingEmailTextColor(color?: string) {
  if (!color) {
    return '#000';
  }

  const luminance = getLuminance(color);

  return luminance > 0.5 ? '#000000' : '#ffffff';
}
