import { MuiTypography } from '@/components/mui/Typography';
import cx from 'classnames';

type BlankTableNoticeProps = {
  title: JSX.Element;
  illustration?: JSX.Element;
  message?: JSX.Element;
  actions?: JSX.Element;
  titleClassName?: string;
  messageClassName?: string;
};

export default function BlankTableNotice({
  illustration,
  title,
  titleClassName,
  message,
  messageClassName,
  actions,
}: BlankTableNoticeProps) {
  return (
    <section className="flex flex-col items-center justify-center gap-2 p-8 text-center">
      {illustration}

      <div
        className={cx('flex max-w-sm flex-col gap-1', {
          'mb-6': !!actions,
        })}
      >
        <MuiTypography variant="h5" className={titleClassName}>
          {title}
        </MuiTypography>

        <MuiTypography variant="subtitle1" className={messageClassName}>
          {message}
        </MuiTypography>
      </div>

      {actions}
    </section>
  );
}
