import { cx } from '@/themes/utils/cssUtils';
import productLabels from '@/assets/json/product-labels.json';

const modules = import.meta.glob('@/assets/png/icons/*.png', { eager: true });

type ProductList =
  | 'platform'
  | 'onboard'
  | 'i9Center'
  | 'compliance'
  | 'ocr'
  | 'communicate'
  | 'pool'
  | 'pulse'
  | 'referrals'
  | 'shift'
  | 'support';
export type AvailableSizes = 20 | 32 | 96 | 120;

interface ProductIconProps {
  product: ProductList;
  size?: AvailableSizes;
  hoverable?: boolean;
}

export default function ProductIcon({
  product,
  size = 32,
  hoverable = false,
}: ProductIconProps) {
  function getSizeClassName(size: AvailableSizes) {
    switch (size) {
      case 20:
        return 'size-5';
      case 32:
        return 'size-8';
      case 96:
        return 'size-24';
      case 120:
        return 'size-[120px]';
    }
  }

  function getSizeSuffixe(size: AvailableSizes) {
    switch (size) {
      case 20:
      case 32:
        return 'sm';
      case 96:
        return 'md';
      case 120:
        return 'lg';
    }
  }

  function getIcon(mode: 'active' | 'inactive' = 'active', suffix?: '@2x') {
    const iconModuleIndex = Object.keys(modules).findIndex(module => {
      switch (product) {
        case 'platform':
          return module.includes(
            `home_${mode}_${getSizeSuffixe(size)}${suffix ?? ''}.png`,
          );
        case 'i9Center':
          return module.includes(
            `i9_${mode}_${getSizeSuffixe(size)}${suffix ?? ''}.png`,
          );
        default:
          return module.includes(
            `${product}_${mode}_${getSizeSuffixe(size)}${suffix ?? ''}.png`,
          );
      }
    });
    const iconModule = Object.values(modules)[iconModuleIndex];

    if (iconModule) {
      return iconModule as { default: string };
    }

    return null;
  }

  return (
    <>
      <img
        src={getIcon()?.default}
        srcSet={`${getIcon('active', '@2x')?.default} 2x`}
        alt={productLabels[product]}
        className={cx(getSizeClassName(size), {
          'hidden group-hover:inline-block': hoverable,
        })}
        aria-hidden="true"
      />
      <img
        src={getIcon('inactive')?.default}
        srcSet={`${getIcon('inactive', '@2x')?.default} 2x`}
        alt={productLabels[product]}
        className={cx(getSizeClassName(size), 'hidden', {
          'inline-block group-hover:hidden': hoverable,
        })}
        aria-hidden="true"
      />
    </>
  );
}

export function PrimaryProductIcon({ product }: { product: ProductList }) {
  function getIcon(mode: 'active' | 'inactive' = 'active', suffix?: '@2x') {
    const iconModuleIndex = Object.keys(modules).findIndex(module => {
      switch (product) {
        case 'platform':
          return module.includes(`home_${mode}_sm${suffix ?? ''}.png`);
        case 'i9Center':
          return module.includes(`i9_${mode}_sm${suffix ?? ''}.png`);
        default:
          return module.includes(`${product}_${mode}_sm${suffix ?? ''}.png`);
      }
    });
    const iconModule = Object.values(modules)[iconModuleIndex];

    if (iconModule) {
      return iconModule as { default: string };
    }

    return null;
  }

  return (
    <img
      src={getIcon()?.default}
      srcSet={`${getIcon('active', '@2x')?.default} 2x`}
      alt={productLabels[product]}
      className="size-8"
      aria-hidden="true"
    />
  );
}
