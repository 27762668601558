import { OpenAPI as Logger } from '@fountain/wx-api-clients/generated/logger';
import { OpenAPI as Media } from '@fountain/wx-api-clients/generated/media';
import { OpenAPI as Messaging } from '@fountain/wx-api-clients/generated/messaging';
import { OpenAPI as Organizations } from '@fountain/wx-api-clients/generated/organizations';
import { OpenAPI as Scheduler } from '@fountain/wx-api-clients/generated/scheduler';
import { OpenAPI as Security } from '@fountain/wx-api-clients/generated/security';
import { OpenAPI as Segmentation } from '@fountain/wx-api-clients/generated/segmentation';
import { OpenAPI as Staff } from '@fountain/wx-api-clients/generated/staff';
import { OpenAPI as Todo } from '@fountain/wx-api-clients/generated/todo';
import { OpenAPI as Workforce } from '@fountain/wx-api-clients/generated/workforce';
import { OpenAPI as Compliance } from '@fountain/wx-api-clients/generated/compliance';
import { OpenAPI as ComplianceV2 } from '@fountain/wx-api-clients/generated/compliancev2';
import { OpenAPI as Pool } from '@fountain/wx-api-clients/generated/pool';
import { OpenAPI as Communicate } from '@fountain/wx-api-clients/generated/communicate';
import { OpenAPI as Authorization } from '@fountain/wx-api-clients/generated/authorization';
import { OpenAPI as Pulse } from '@fountain/wx-api-clients/generated/pulse';
import { OpenAPI as Referral } from '@fountain/wx-api-clients/generated/referral';
import { OpenAPI as Attendance } from '@fountain/wx-api-clients/generated/attendance';
import { OpenAPI as Employment } from '@fountain/wx-api-clients/generated/employment';
import { OpenAPI as Integrations } from '@fountain/wx-api-clients/generated/integrations';
import { OpenAPI as Support } from '@fountain/wx-api-clients/generated/support';
import { OpenAPI as OCR } from '@fountain/wx-api-clients/generated/ocr';

export function setBearerToken(token: string) {
  Logger.TOKEN = token;
  Media.TOKEN = token;
  Messaging.TOKEN = token;
  Organizations.TOKEN = token;
  Scheduler.TOKEN = token;
  Security.TOKEN = token;
  Segmentation.TOKEN = token;
  Staff.TOKEN = token;
  Todo.TOKEN = token;
  Workforce.TOKEN = token;
  Compliance.TOKEN = token;
  ComplianceV2.TOKEN = token;
  Pool.TOKEN = token;
  Communicate.TOKEN = token;
  Authorization.TOKEN = token;
  Pulse.TOKEN = token;
  Referral.TOKEN = token;
  Attendance.TOKEN = token;
  Employment.TOKEN = token;
  Integrations.TOKEN = token;
  Support.TOKEN = token;
  OCR.TOKEN = token;
}

export function removeBearerToken() {
  Logger.TOKEN = undefined;
  Media.TOKEN = undefined;
  Messaging.TOKEN = undefined;
  Organizations.TOKEN = undefined;
  Scheduler.TOKEN = undefined;
  Security.TOKEN = undefined;
  Segmentation.TOKEN = undefined;
  Staff.TOKEN = undefined;
  Todo.TOKEN = undefined;
  Workforce.TOKEN = undefined;
  Compliance.TOKEN = undefined;
  ComplianceV2.TOKEN = undefined;
  Pool.TOKEN = undefined;
  Communicate.TOKEN = undefined;
  Authorization.TOKEN = undefined;
  Pulse.TOKEN = undefined;
  Referral.TOKEN = undefined;
  Attendance.TOKEN = undefined;
  Employment.TOKEN = undefined;
  Integrations.TOKEN = undefined;
  Support.TOKEN = undefined;
  OCR.TOKEN = undefined;
}

export function setBaseUrl() {
  Organizations.BASE =
    import.meta.env.VITE_ORGANIZATIONS_SERVICE_BASE_URL ||
    'http://localhost:8101';
  Media.BASE =
    import.meta.env.VITE_MEDIA_SERVICE_BASE_URL || 'http://localhost:8102';
  Logger.BASE =
    import.meta.env.VITE_LOGGER_SERVICE_BASE_URL || 'http://localhost:8103';
  Messaging.BASE =
    import.meta.env.VITE_MESSAGING_SERVICE_BASE_URL || 'http://localhost:8104';
  Segmentation.BASE =
    import.meta.env.VITE_SEGMENTATION_SERVICE_BASE_URL ||
    'http://localhost:8105';
  Scheduler.BASE =
    import.meta.env.VITE_SCHEDULER_SERVICE_BASE_URL || 'http://localhost:8106';
  Security.BASE =
    import.meta.env.VITE_SECURITY_SERVICE_BASE_URL || 'http://localhost:8107';
  Staff.BASE =
    import.meta.env.VITE_STAFF_SERVICE_BASE_URL || 'http://localhost:8108';
  Todo.BASE =
    import.meta.env.VITE_TODO_SERVICE_BASE_URL || 'http://localhost:8109';
  Workforce.BASE =
    import.meta.env.VITE_WORKFORCE_SERVICE_BASE_URL || 'http://localhost:8110';
  Compliance.BASE =
    import.meta.env.VITE_COMPLIANCE_SERVICE_BASE_URL || 'http://localhost:8111';
  Communicate.BASE =
    import.meta.env.VITE_COMMUNICATE_SERVICE_BASE_URL ||
    'http://localhost:8113';
  Authorization.BASE =
    import.meta.env.VITE_AUTHORIZATION_SERVICE_BASE_URL ||
    'http://localhost:8114';
  Pulse.BASE =
    import.meta.env.VITE_PULSE_SERVICE_BASE_URL || 'http://localhost:8115';
  Referral.BASE =
    import.meta.env.VITE_REFERRAL_SERVICE_BASE_URL || 'http://localhost:8116';
  Attendance.BASE =
    import.meta.env.VITE_ATTENDANCE_SERVICE_BASE_URL || 'http://localhost:8117';
  Employment.BASE =
    import.meta.env.VITE_EMPLOYMENT_SERVICE_BASE_URL || 'http://localhost:8118';
  Integrations.BASE =
    import.meta.env.VITE_INTEGRATIONS_SERVICE_BASE_URL ||
    'http://localhost:8119';
  Support.BASE =
    import.meta.env.VITE_SUPPORT_SERVICE_BASE_URL || 'http://localhost:8120';
  ComplianceV2.BASE =
    import.meta.env.VITE_COMPLIANCEV2_SERVICE_BASE_URL ||
    'http://localhost:8121';
  Pool.BASE =
    import.meta.env.VITE_POOL_SERVICE_BASE_URL || 'http://localhost:8123';
  OCR.BASE =
    import.meta.env.VITE_OCR_SERVICE_BASE_URL || 'http://localhost:8122';
}

export function setDebugHeader() {
  const debugHeader = { 'X-Authz-Development': 'true' };

  Logger.HEADERS = debugHeader;
  Media.HEADERS = debugHeader;
  Messaging.HEADERS = debugHeader;
  Organizations.HEADERS = debugHeader;
  Scheduler.HEADERS = debugHeader;
  Security.HEADERS = debugHeader;
  Segmentation.HEADERS = debugHeader;
  Staff.HEADERS = debugHeader;
  Todo.HEADERS = debugHeader;
  Workforce.HEADERS = debugHeader;
  Compliance.HEADERS = debugHeader;
  Communicate.HEADERS = debugHeader;
  Authorization.HEADERS = debugHeader;
  Pulse.HEADERS = debugHeader;
  Referral.HEADERS = debugHeader;
  Attendance.HEADERS = debugHeader;
  Employment.HEADERS = debugHeader;
  Integrations.HEADERS = debugHeader;
  Support.HEADERS = debugHeader;
  ComplianceV2.HEADERS = debugHeader;
  OCR.HEADERS = debugHeader;
}

export function removeDebugHeader() {
  Logger.HEADERS = undefined;
  Media.HEADERS = undefined;
  Messaging.HEADERS = undefined;
  Organizations.HEADERS = undefined;
  Scheduler.HEADERS = undefined;
  Security.HEADERS = undefined;
  Segmentation.HEADERS = undefined;
  Staff.HEADERS = undefined;
  Todo.HEADERS = undefined;
  Workforce.HEADERS = undefined;
  Compliance.HEADERS = undefined;
  Communicate.HEADERS = undefined;
  Authorization.HEADERS = undefined;
  Pulse.HEADERS = undefined;
  Referral.HEADERS = undefined;
  Attendance.HEADERS = undefined;
  Employment.HEADERS = undefined;
  Integrations.HEADERS = undefined;
  Support.HEADERS = undefined;
  ComplianceV2.HEADERS = undefined;
  OCR.HEADERS = undefined;
}
